export default {
  ESTIMATES_LIST: "List of estimates",
  ADD_ESTIMATE: "Add an estimate",
  VIEW_ESTIMATE: "estimation",
  EDIT_ESTIMATE: "Modify an estimate",
  ESTIMATE_ADDED: "Added estimate",
  ESTIMATE_UPDATED: "Update estimate",
  DELETE_THIS_ESTIMATE: "Delete this estimate?",
  ESTIMATE_DELETED: "Deleted estimate",
  ESTIMATE_STOCK: "stock",
  ESTIMATE_TYPE: "type",
  CREATOR: "Creator",
  ALL: "All",
  APPROVED_ALL: "Approve everything",
  ESTIMATE_STATUS_DRAFT: "Draft",
  ESTIMATE_STATUS_CANCELED: "Canceled",
  ESTIMATE_STATUS_COMPLETED: "Supplemented",
  ESTIMATE_STATUS_ASSIGNED: "Assigned",
  ESTIMATE_STATUS_APPROVED: "Approved",
  ESTIMATE_STATUS_REVIEWED: "revue",
  ESTIMATE_STATUS_DENIED: "Refused",
  ESTIMATE_STATUS_PENDING_COMPONENTS: "Waiting parts",
  ESTIMATE_STATUS_IN_PROGRESS_COMPONENTS: "In class",
  ESTIMATE_STATUS_PENDING_TIMES: "Pending time",
  ESTIMATE_STATUS_IN_PROGRESS_TIMES: "In progress",
  ESTIMATE_STATUS_PENDING_APPROVE: "Awaiting Approval",
  ESTIMATE_STATUS_IN_PROGRESS_APPROVE: "Pending approval",
  ESTIMATE_STATUS_PENDING: "On hold",
  ESTIMATE_STATUS_IN_PROGRESS: "In progress",
  ESTIMATE_TYPES_STATUS_PENDING: "On hold",
  ESTIMATE_TYPES_STATUS_IN_PROGRESS: "In progress",
  ESTIMATE_TYPES_STATUS_COMPLETED: "Finished",
  APPROVED_AT: "Approved the",
  APPROVED_BY: "Approved by",
  ASSIGNED_BY: "Assigned by",
  CANCELED_BY: "Canceled by",
  CANCELED_AT: "Canceled the",
  COMPLETED_BY: "Supplemented by",
  ASSIGNED_TO: "Assigned to",
  ASSIGNED_AT: "Assigned the",
  REVIEWED_BY: "Review by",
  REVIEWED_AT: "Review",
  DENIED_BY: "Refused by",
  DENIED_AT: "Refused",
  ESTIMATE_CLIENT: "client",
  ASSIGNED_TO_ME: "Assigned to me",
  CREATED_BY_ME: "Created by me",
  COMPLETED_AT: "Completed",
  ESTIMATE_CLIENT: "client",
  ESTIMATE_DEPARTMENT: "Department",
  ESTIMATE_ALL_DEPARTMENTS: "Departments",
  ESTIMATE_ASSIGN_TO: "Assigned to",
  ESTIMATE_TYPE: "typeD'estimation",
  ESTIMATE_DETAIL: "Detail of the estimate",
  ESTIMATE_ITEM: "item",
  ESTIMATE_ITEM_DETAIL: "Detail",
  ESTIMATE_ITEM_STATE: "état",
  ESTIMATE_ITEM_PART: "pièce",
  ESTIMATE_ITEM_PART_NUMBER: "Part",
  ESTIMATE_ITEM_MANUAL_LABOR: "Workforce",
  ESTIMATE_ITEM_TIME: "temps",
  ESTIMATE_ITEM_HOURLY_RATE: "Time rate",
  ESTIMATE_ITEM_APPROVED: "Approve",
  ESTIMATE_ITEM_REVIEW: "Revise",
  ESTIMATE_ITEM_REFUSED: "Refuse",
  ESTIMATE_ITEM_STATUS_APPROVED: "Approve",
  ESTIMATE_ITEM_STATUS_REVIEWED: "Revise",
  ESTIMATE_ITEM_STATUS_DENIED: "Refuse",
  ESTIMATE_ITEM_CONDITION_TO_REPLACE: "To replace",
  ADD_ESTIMATE_ITEM_: "Add an item",
  TO_REPLACE: "To replace",
  ESTIMATE_ITEM_CONDITION_TO_REPAIR: "To fix",
  TO_REPAIR: "To fix",
  VEHICLE_VIN: "Vehicle wine",
  SAVE_AND_SENT: "Save and send",
  REMOVE_ITEM: "Withdraw",
  ESTIMATE_ITEM_ADDED: "Added element",
  DELETE_THIS_ESTIMATE_ITEM: "Delete this estimate?",
  DELETE_THIS_ITEM_FROM_ESTIMATE: "Do you want to delete this estimate?",
  ESTIMATE_ITEM_DELETED: "Deleted element",
  APPROVE_ESTIMATE: "Approve the estimate",
  ESTIMATE_ASSIGNED: "Assigned estimate",
  ESTIMATE_APPROVED: "Approved estimate",
  ASSIGN_ESTIMATE: "Assign the estimate",
  ESTIMATE_CANCELED: "Canceled estimate",
  CANCEL_ESTIMATE: "Cancel the estimate",
  CANCEL_THIS_ESTIMATE: "Do you want to cancel this estimate?",
  ESTIMATE_DENIED: "Refused estimate",
  DENY_ESTIMATE: "Refuse the estimate",
  ESTIMATE_COMPLETED: "Estimate completed",
  COMPLETE_ESTIMATE: "Finish the estimate",
  ESTIMATE_REVIEWED: "Estimate to review",
  REVIEW_ESTIMATE: "Review the estimate",
  NEXT_STEP: "Next step",
  SAVE_VEHICLE: "Save the vehicle",

  START_COMPONENTS_ESTIMATE: "Start the editing of the estimate parts",
  START_THIS_COMPONENTS_ESTIMATE:
    "Do you want to start the estimation of the parts?",
  ESTIMATE_STARTED_COMPONENTS: "Edition of the parts of the estimate started",
  STARTED_COMPONENTS_AT: "Edition of the parts started on",
  STARTED_COMPONENTS_BY: "Edition of the pieces started by",

  END_COMPONENTS_ESTIMATE: "Finish the parts estimate",
  END_THIS_COMPONENTS_ESTIMATE:
    "Do you want to finish the estimation of the parts?",
  END_THIS_COMPONENTS_ESTIMATE_WITHOUT_COMPONENTS:
    "Do you want to finish the estimate without parts?",
  ESTIMATE_ENDED_COMPONENTS: "Edition of the Estimated Estimated Parts",
  ENDED_COMPONENTS_AT: "Edition of the parts completed on",
  ENDED_COMPONENTS_BY: "Edition of the parts completed by",

  START_TIMES_ESTIMATE: "Start the edition of the estimate times",
  START_THIS_TIMES_ESTIMATE: "Do you want to start the time estimate?",
  ESTIMATE_STARTED_TIMES: "Edition of the time of the estimate started",
  STARTED_TIMES_AT: "Edition of Times started the",
  STARTED_TIMES_BY: "Edition of time started by",

  END_TIMES_ESTIMATE: "Finish the hands of work",
  END_THIS_TIMES_ESTIMATE: "Do you want to finish the time estimate?",
  ESTIMATE_ENDED_TIMES: "Edition of the times of the ended estimate",
  ENDED_TIMES_AT: "Edition of time completed on",
  ENDED_TIMES_BY: "Edition of time ended with",

  START_APPROVE_ESTIMATE: "Start approval of the estimate",
  START_THIS_APPROVE_ESTIMATE: "Do you want to start approval of estimates?",
  ESTIMATE_STARTED_APPROVE: "Approval of the estimate started",
  STARTED_APPROVE_AT: "Approval started on",
  STARTED_APPROVE_BY: "Approval started by",

  END_APPROVE_ESTIMATE: "Finish approval",
  END_THIS_APPROVE_ESTIMATE:
    "Do you want to finish the approval of the estimates?",
  ESTIMATE_ENDED_APPROVE: "Approval of the ended estimate",
  ENDED_APPROVE_AT: "Approval completed on",
  ENDED_APPROVE_BY: "Approval ended by",

  VALID_ESTIMATE: "Validate the estimate",
  ESTIMATE_VALIDATED: "Validated estimate",
  VALIDATED_AT: "Validated the",
  VALIDATED_BY: "Validated by",
  VALIDATE_THIS_ESTIMATE: "Do you want to validate this estimate?",

  TIMES: "Workforce",
  COMPONENTS: "Pieces",
  TIMES_STATUS: "Time state",
  COMPONENTS_STATUS: "Part of the parts",

  ESTIMATE_IN_PROGRESS_WARNING: "The estimate is being modified",

  COMPONENTS_ESTIMATOR: "Piece estimator",
  TIMES_ESTIMATOR: "Labor estimator",

  START_ESTIMATION: "Start the estimate",
  END_ESTIMATION: "Finish the estimate",

  ESTIMATE_WITHOUT_COMPONENTS: "Estimate without the parts",
  ESTIMATE_WITHOUT_TIMES: "Estimate without time",
  NO_COMPONENTS_ADDED: "No parts is added for this estimate",
  OTHER_WORK_ON_ESTIMATE: "Another user is already working on this estimate",

  GO_TO_TIMES: "Go to the hands of work",

  BACK_TO_COMPONENTS: "Go back",
  GO_TO_APPROVE: "Go to approval",

  BACK_TO_TIMES: "Go back",

  MECHANICS: "Mechanics",
  OFFICIAL_MECHANIC: "Official mechanic",
  CREATED_AT: "Created at",
  ASSIGNED: "Assigned",
  CANCELED: "Canceled",
  UPDATED_AT: "Updated at",
  COMPLETED: "Completed",

  TAKE_OVER_ESTIMATE: "Take over estimation",
  TAKE_OVER_THIS_INSPECTION:
    "This estimation is in progress by {name}. \n Do you want to take it over?",
  YES_TAKE_OVER: "Yes, take over the estimation",
  COMPONENTS_TOOK_OVER: "Components <u>took over</u>",
  TIMES_TOOK_OVER: "Times <u>took over</u>",

  CHANGE_COMPONENTS_MANAGER: "Change components manager",
  CHANGE_TIMES_MANAGER: "Change times manager",

  ESTIMATE_ALREADY_EXIST:
    "An estimation is already in progress for this vehicle",

  REVIEW_ALERT:
    "The estimation will be returned to <b>in progress</b> for the processing of revisions",
};
