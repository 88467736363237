export default {
  PERM_VIEW_MODULE_VEHICLES: "Access the vehicle module",
  PERM_VIEW_ANY_VEHICLES: "View all vehicles",
  PERM_VIEW_VEHICLES: "View vehicle details",
  PERM_CREATE_VEHICLES: "Create a vehicle",
  PERM_EDIT_VEHICLES: "Edit vehicles",
  PERM_DELETE_VEHICLES: "Delete a vehicle",

  PERM_VIEW_MODULE_VEHICLE_ESTIMATIONS:
    "Access the vehicle price estimation module",
  PERM_MAKE_API_VEHICLE_ESTIMATIONS: "Make a vehicle price estimate via API",
  PERM_MAKE_IFRAME_VEHICLE_ESTIMATIONS:
    "Make a vehicle price estimate via iFrame",
  PERM_VIEW_VEHICLE_ESTIMATIONS_DEBUG_MODE:
    "Access vehicle price estimation debug mode",

  PERM_VIEW_ANY_VEHICLE_ESTIMATIONS_DOMAINS: "View all domains",
  PERM_VIEW_VEHICLE_ESTIMATIONS_DOMAINS: "View domain details",
  PERM_CREATE_VEHICLE_ESTIMATIONS_DOMAINS: "Add a domain",
  PERM_EDIT_VEHICLE_ESTIMATIONS_DOMAINS: "Edit a domain",
  PERM_DELETE_VEHICLE_ESTIMATIONS_DOMAINS: "Delete a domain",

  PERM_VIEW_MODULE_REQUESTS: "Access the request module",
  PERM_VIEW_ANY_REQUESTS: "View all requests",
  PERM_VIEW_REQUESTS: "View request details",
  PERM_CREATE_REQUESTS: "Create a request",
  PERM_EDIT_REQUESTS: "Edit requests",
  PERM_DELETE_REQUESTS: "Delete a request",
  PERM_APPROVE_REQUESTS: "Approve requests",
  PERM_ASSIGN_REQUESTS: "Assign requests",
  PERM_CANCEL_REQUESTS: "Cancel requests",
  PERM_COMPLETE_REQUESTS: "Complete requests",
  PERM_MAKE_INSPECTIONS_REQUESTS: "Make inspection requests",
  PERM_MAKE_REPARATIONS_REQUESTS: "Make reparation requests",
  PERM_MAKE_ESTIMATIONS_REQUESTS: "Make estimation requests",
  PERM_MAKE_EVALUATION_REQUESTS: "Make estimation requests",

  PERM_VIEW_MODULE_INSPECTIONS: "Access the inspection module",
  PERM_VIEW_ANY_INSPECTIONS: "View all inspections",
  PERM_VIEW_INSPECTIONS: "View inspection details",
  PERM_VIEW_ASSIGNED_TO_GROUP_INSPECTIONS:
    "View inspections assigned to their group",
  PERM_CREATE_INSPECTIONS: "Create an inspection",
  PERM_EDIT_INSPECTIONS: "Edit inspections",
  PERM_DELETE_INSPECTIONS: "Delete an inspection",
  PERM_APPROVE_INSPECTIONS: "Approve inspections",
  PERM_ASSIGN_INSPECTIONS: "Assign inspections",
  PERM_CANCEL_INSPECTIONS: "Cancel inspections",
  PERM_START_INSPECTIONS: "Start an inspection",
  PERM_COMPLETE_INSPECTIONS: "Complete inspections",
  PERM_TAKE_OVER_INSPECTIONS: "Take over an an inspection",
  PERM_VIEW_IN_PROGRESS_INSPECTIONS: "View in progress inspections",

  PERM_VIEW_MODULE_INSPECTION_FORMS: "Access the inspection forms module",
  PERM_VIEW_ANY_INSPECTION_FORMS: "View all inspection forms",
  PERM_VIEW_INSPECTION_FORMS: "View inspection form details",
  PERM_CREATE_INSPECTION_FORMS: "Create an inspection form",
  PERM_EDIT_INSPECTION_FORMS: "Edit inspection forms",
  PERM_DELETE_INSPECTION_FORMS: "Delete an inspection form",

  PERM_VIEW_MODULE_IMPORTS: "Access the import module",
  PERM_VIEW_ANY_IMPORTS: "View all imports",
  PERM_VIEW_IMPORTS: "View import details",
  PERM_CREATE_IMPORTS: "Create an import",
  PERM_EDIT_IMPORTS: "Edit imports",
  PERM_DELETE_IMPORTS: "Delete an import",

  PERM_VIEW_MODULE_STOCK_MOVEMENTS: "Access the stock movements module",
  PERM_VIEW_ANY_STOCK_MOVEMENTS: "View all stock movements",
  PERM_VIEW_STOCK_MOVEMENTS: "View stock movement details",
  PERM_CREATE_STOCK_MOVEMENTS: "Create a stock movement",
  PERM_EDIT_STOCK_MOVEMENTS: "Edit stock movements",
  PERM_DELETE_STOCK_MOVEMENTS: "Delete a stock movement",

  PERM_VIEW_MODULE_WAREHOUSES: "Access the warehouses module",
  PERM_VIEW_ANY_WAREHOUSES: "View all warehouses",
  PERM_VIEW_WAREHOUSES: "View warehouse details",
  PERM_CREATE_WAREHOUSES: "Create a warehouse",
  PERM_EDIT_WAREHOUSES: "Edit warehouses",
  PERM_DELETE_WAREHOUSES: "Delete a warehouse",

  PERM_VIEW_MODULE_RESELLER_PAYMENTS: "Access the reseller payments module",
  PERM_VIEW_ANY_RESELLER_PAYMENTS: "View all reseller payments",
  PERM_VIEW_RESELLER_PAYMENTS: "View reseller payment details",
  PERM_CREATE_RESELLER_PAYMENTS: "Create a reseller payment",
  PERM_EDIT_RESELLER_PAYMENTS: "Edit reseller payments",
  PERM_DELETE_RESELLER_PAYMENTS: "Delete a reseller payment",

  PERM_VIEW_MODULE_RESELLER_INVOICES: "Access the reseller invoices module",
  PERM_VIEW_ANY_RESELLER_INVOICES: "View all reseller invoices",
  PERM_VIEW_RESELLER_INVOICES: "View reseller invoice details",
  PERM_CREATE_RESELLER_INVOICES: "Create a reseller invoice",
  PERM_EDIT_RESELLER_INVOICES: "Edit reseller invoices",
  PERM_DELETE_RESELLER_INVOICES: "Delete a reseller invoice",

  PERM_VIEW_MODULE_RESELLER_SERVICES: "Access the reseller services module",
  PERM_VIEW_ANY_RESELLER_SERVICES: "View all reseller services",
  PERM_VIEW_RESELLER_SERVICES: "View reseller service details",
  PERM_CREATE_RESELLER_SERVICES: "Create a reseller service",
  PERM_EDIT_RESELLER_SERVICES: "Edit reseller services",
  PERM_DELETE_RESELLER_SERVICES: "Delete a reseller service",

  PERM_VIEW_MODULE_RESELLER_PRODUCTS: "Access the reseller products module",
  PERM_VIEW_ANY_RESELLER_PRODUCTS: "View all reseller products",
  PERM_VIEW_RESELLER_PRODUCTS: "View reseller product details",
  PERM_CREATE_RESELLER_PRODUCTS: "Create a reseller product",
  PERM_EDIT_RESELLER_PRODUCTS: "Edit reseller products",
  PERM_DELETE_RESELLER_PRODUCTS: "Delete a reseller product",

  PERM_VIEW_MODULE_SALES_PAYMENTS: "Access the sales payments module",
  PERM_VIEW_ANY_SALES_PAYMENTS: "View all sales payments",
  PERM_VIEW_SALES_PAYMENTS: "View sales payment details",
  PERM_CREATE_SALES_PAYMENTS: "Create a sales payment",
  PERM_EDIT_SALES_PAYMENTS: "Edit sales payments",
  PERM_DELETE_SALES_PAYMENTS: "Delete a sales payment",

  PERM_VIEW_MODULE_SALES_DELIVERIES: "Access the sales deliveries module",
  PERM_VIEW_ANY_SALES_DELIVERIES: "View all sales deliveries",
  PERM_VIEW_SALES_DELIVERIES: "View sales delivery details",
  PERM_CREATE_SALES_DELIVERIES: "Create a sales delivery",
  PERM_EDIT_SALES_DELIVERIES: "Edit sales deliveries",
  PERM_DELETE_SALES_DELIVERIES: "Delete a sales delivery",

  PERM_VIEW_MODULE_SALES_INVOICES: "Access the sales invoices module",
  PERM_VIEW_ANY_SALES_INVOICES: "View all sales invoices",
  PERM_VIEW_SALES_INVOICES: "View sales invoice details",
  PERM_CREATE_SALES_INVOICES: "Create a sales invoice",
  PERM_EDIT_SALES_INVOICES: "Edit sales invoices",
  PERM_DELETE_SALES_INVOICES: "Delete a sales invoice",

  PERM_VIEW_MODULE_SALES_ORDERS: "Access the sales orders module",
  PERM_VIEW_ANY_SALES_ORDERS: "View all sales orders",
  PERM_VIEW_SALES_ORDERS: "View sales order details",
  PERM_CREATE_SALES_ORDERS: "Create a sales order",
  PERM_EDIT_SALES_ORDERS: "Edit sales orders",
  PERM_DELETE_SALES_ORDERS: "Delete a sales order",

  PERM_VIEW_MODULE_PURCHASES_PAYMENTS: "Access the purchases payments module",
  PERM_VIEW_ANY_PURCHASES_PAYMENTS: "View all purchases payments",
  PERM_VIEW_PURCHASES_PAYMENTS: "View purchases payment details",
  PERM_CREATE_PURCHASES_PAYMENTS: "Create a purchases payment",
  PERM_EDIT_PURCHASES_PAYMENTS: "Edit purchases payments",
  PERM_DELETE_PURCHASES_PAYMENTS: "Delete a purchases payment",

  PERM_VIEW_MODULE_PURCHASES_DELIVERIES:
    "Access the purchases deliveries module",
  PERM_VIEW_ANY_PURCHASES_DELIVERIES: "View all purchases deliveries",
  PERM_VIEW_PURCHASES_DELIVERIES: "View purchases delivery details",
  PERM_CREATE_PURCHASES_DELIVERIES: "Create a purchases delivery",
  PERM_EDIT_PURCHASES_DELIVERIES: "Edit purchases deliveries",
  PERM_DELETE_PURCHASES_DELIVERIES: "Delete a purchases delivery",

  PERM_VIEW_MODULE_PURCHASES_INVOICES: "Access the purchases invoices module",
  PERM_VIEW_ANY_PURCHASES_INVOICES: "View all purchases invoices",
  PERM_VIEW_PURCHASES_INVOICES: "View purchases invoice details",
  PERM_CREATE_PURCHASES_INVOICES: "Create a purchases invoice",
  PERM_CREATE_PURCHASES_INVOICES: "Create a purchase invoice",
  PERM_EDIT_PURCHASES_INVOICES: "Edit purchase invoices",
  PERM_DELETE_PURCHASES_INVOICES: "Delete a purchase invoice",

  PERM_VIEW_MODULE_PURCHASES_ORDERS: "Access the purchase orders module",
  PERM_VIEW_ANY_PURCHASES_ORDERS: "View all purchase orders",
  PERM_VIEW_PURCHASES_ORDERS: "View purchase order details",
  PERM_CREATE_PURCHASES_ORDERS: "Create a purchase order",
  PERM_EDIT_PURCHASES_ORDERS: "Edit purchase orders",
  PERM_DELETE_PURCHASES_ORDERS: "Delete a purchase order",

  PERM_VIEW_MODULE_FOLDERS: "Access the folders module",
  PERM_VIEW_ANY_FOLDERS: "View all folders",
  PERM_VIEW_FOLDERS: "View folder details",
  PERM_CREATE_FOLDERS: "Create a folder",
  PERM_EDIT_FOLDERS: "Edit folders",
  PERM_DELETE_FOLDERS: "Delete a folder",
  PERM_MANAGE_ACCESS_FOLDERS: "Manage permissions",
  PERM_MANAGE_LOCKED_FOLDERS: "Manage system folders",

  PERM_VIEW_MODULE_FILES: "Access the files module",
  PERM_VIEW_ANY_FILES: "View all files",
  PERM_VIEW_FILES: "View file details",
  PERM_CREATE_FILES: "Create a file",
  PERM_EDIT_FILES: "Edit files",
  PERM_DELETE_FILES: "Delete a file",
  PERM_MANAGE_ACCESS_FILES: "Manage permissions",

  PERM_VIEW_MODULE_LOCATIONS: "Access the locations module",
  PERM_VIEW_ANY_LOCATIONS: "View all locations",
  PERM_VIEW_LOCATIONS: "View location details",
  PERM_CREATE_LOCATIONS: "Create a location",
  PERM_EDIT_LOCATIONS: "Edit locations",
  PERM_EDIT_HIS_LOCATIONS: "Edit their location",
  PERM_DELETE_LOCATIONS: "Delete a location",

  PERM_VIEW_MODULE_SUPPLIERS: "Access the suppliers module",
  PERM_VIEW_ANY_SUPPLIERS: "View all suppliers",
  PERM_VIEW_SUPPLIERS: "View supplier details",
  PERM_CREATE_SUPPLIERS: "Create a supplier",
  PERM_EDIT_SUPPLIERS: "Edit suppliers",
  PERM_DELETE_SUPPLIERS: "Delete a supplier",

  PERM_VIEW_MODULE_CONTACTS: "Access the contacts module",
  PERM_VIEW_ANY_CONTACTS: "View all contacts",
  PERM_VIEW_CONTACTS: "View contact details",
  PERM_CREATE_CONTACTS: "Create a contact",
  PERM_EDIT_CONTACTS: "Edit contacts",
  PERM_DELETE_CONTACTS: "Delete a contact",

  PERM_VIEW_MODULE_PAYMENTS: "Access the payments module",
  PERM_VIEW_ANY_PAYMENTS: "View all payments",
  PERM_VIEW_PAYMENTS: "View payment details",
  PERM_CREATE_PAYMENTS: "Create a payment",

  PERM_VIEW_MODULE_SUBSCRIPTIONS: "Access the subscriptions module",
  PERM_VIEW_ANY_SUBSCRIPTIONS: "View all subscriptions",
  PERM_VIEW_SUBSCRIPTIONS: "View subscription details",
  PERM_CREATE_SUBSCRIPTIONS: "Create a subscription",
  PERM_EDIT_SUBSCRIPTIONS: "Edit subscriptions",
  PERM_DELETE_SUBSCRIPTIONS: "Delete a subscription",

  PERM_VIEW_MODULE_PACKAGES: "Access the packages module",
  PERM_VIEW_ANY_PACKAGES: "View all packages",
  PERM_VIEW_PACKAGES: "View package details",
  PERM_CREATE_PACKAGES: "Create a package",
  PERM_EDIT_PACKAGES: "Edit packages",
  PERM_DELETE_PACKAGES: "Delete a package",

  PERM_VIEW_MODULE_CUSTOMERS: "Access the customers module",
  PERM_VIEW_ANY_CUSTOMERS: "View all customers",
  PERM_VIEW_CUSTOMERS: "View customer details",
  PERM_CREATE_CUSTOMERS: "Create a customer",
  PERM_EDIT_CUSTOMERS: "Edit customers",
  PERM_DELETE_CUSTOMERS: "Delete a customer",

  PERM_VIEW_MODULE_INVOICES: "Access the invoices module",
  PERM_VIEW_ANY_INVOICES: "View all invoices",
  PERM_VIEW_INVOICES: "View invoice details",
  PERM_CREATE_INVOICES: "Create an invoice",
  PERM_EDIT_INVOICES: "Edit invoices",
  PERM_DELETE_INVOICES: "Delete an invoice",

  PERM_VIEW_MODULE_PRODUCTS: "Access the products module",
  PERM_VIEW_ANY_PRODUCTS: "View all products",
  PERM_VIEW_PRODUCTS: "View product details",
  PERM_CREATE_PRODUCTS: "Create a product",
  PERM_EDIT_PRODUCTS: "Edit products",
  PERM_DELETE_PRODUCTS: "Delete products",

  PERM_VIEW_MODULE_ORGANIZATIONS: "Access the organizations module",
  PERM_VIEW_ANY_ORGANIZATIONS: "View all organizations",
  PERM_VIEW_ORGANIZATIONS: "View organization details",
  PERM_CREATE_ORGANIZATIONS: "Create an organization",
  PERM_EDIT_ORGANIZATIONS: "Edit organizations",
  PERM_EDIT_OWN_ORGANIZATIONS: "Edit their organization",
  PERM_VIEW_OWN_ORGANIZATIONS: "View their organization",
  PERM_DELETE_ORGANIZATIONS: "Delete organizations",

  PERM_VIEW_MODULE_RESELLERS: "Access the resellers module",
  PERM_VIEW_ANY_RESELLERS: "View all resellers",
  PERM_VIEW_RESELLERS: "View reseller details",
  PERM_CREATE_RESELLERS: "Create a reseller",
  PERM_EDIT_RESELLERS: "Edit resellers",
  PERM_EDIT_OWN_RESELLERS: "Edit their reseller",
  PERM_DELETE_RESELLERS: "Delete resellers",

  PERM_VIEW_MODULE_USERS: "Access the users module",
  PERM_VIEW_ANY_USERS: "View all users",
  PERM_VIEW_USERS: "View user details",
  PERM_CREATE_USERS: "Create a user",
  PERM_EDIT_USERS: "Edit users",
  PERM_DELETE_USERS: "Delete users",

  PERM_VIEW_MODULE_ROLES: "Access the roles module",
  PERM_VIEW_ANY_ROLES: "View all roles",
  PERM_VIEW_ROLES: "View role details",
  PERM_CREATE_ROLES: "Create a role",
  PERM_EDIT_ROLES: "Edit roles",
  PERM_DELETE_ROLES: "Delete roles",

  PERM_VIEW_ANY_PERMISSIONS: "View all permissions",
  PERM_VIEW_PERMISSIONS: "View permission details",

  PERM_VIEW_ANY_LOGS: "View all logs",
  PERM_VIEW_LOGS: "View log details",

  PERM_VIEW_ANY_REQUEST_LOGS: "View all API calls",
  PERM_VIEW_REQUEST_LOGS: "View API call details",
  PERM_DELETE_REQUEST_LOGS: "Delete API calls",

  PERM_VIEW_APP_ADMIN: "View the admin app",
  PERM_VIEW_APP_CRM: "View the CRM app",
  PERM_VIEW_APP_FILE_EXPLORER: "View the file explorer app",
  PERM_VIEW_APP_PURCHASES: "View the purchases app",
  PERM_VIEW_APP_SALES: "View the sales app",
  PERM_VIEW_APP_DEFAULT: "View the default app",
  PERM_VIEW_APP_INVENTORY: "View the inventory app",
  PERM_VIEW_APP_RESELLER: "View the reseller app",
  PERM_VIEW_APP_INSPECTPRO: "View the InspectPro app",
  PERM_VIEW_APP_VEHICLES: "View the vehicles app",

  PERM_APPROVE_ESTIMATIONS: "Approve estimations",
  PERM_ASSIGN_ESTIMATIONS: "Assign estimations",
  PERM_CANCEL_ESTIMATIONS: "Cancel estimations",
  PERM_COMPLETE_ESTIMATIONS: "Complete estimations",
  PERM_CREATE_ESTIMATIONS: "Create estimations",
  PERM_VALID_ESTIMATIONS: "Validate estimations",
  PERM_DELETE_ESTIMATIONS: "Delete estimations",
  PERM_DENY_ESTIMATIONS: "Deny estimations",
  PERM_EDIT_ESTIMATIONS: "Edit estimations",
  PERM_REVIEW_ESTIMATIONS: "Review estimations",
  PERM_VIEW_ANY_ESTIMATIONS: "View all estimations",
  PERM_VIEW_ESTIMATIONS: "View estimation details",
  PERM_VIEW_MODULE_ESTIMATIONS: "Access the estimations module",
  PERM_EDIT_ESTIMATIONS_COMPONENTS: "Edit estimation components",
  PERM_EDIT_ESTIMATIONS_TIMES: "Edit estimation times",
  PERM_TAKE_OVER_ESTIMATIONS: "Take over an estimation",
  PERM_CHANGE_ESTIMATIONS_MANAGER: "Change estimation manager",

  PERM_VIEW_ANY_COMMENTS: "View all comments",
  PERM_VIEW_COMMENTS: "View comments",
  PERM_CREATE_COMMENTS: "Add new comment",
  PERM_EDIT_COMMENTS: "Edit comment",
  PERM_DELETE_COMMENTS: "Delete comment",

  PERM_VIEW_MODULE_OPTIONS: "Access the settings module",
  PERM_VIEW_ANY_OPTIONS: "View any settings",
  PERM_EDIT_OPTIONS: "Edit settings",

  PERM_VIEW_MODULE_TAXES: "Access the tax module",
  PERM_VIEW_ANY_TAXES: "View any tax",
  PERM_VIEW_TAXES: "View tax details",
  PERM_CREATE_TAXES: "Add new tax",
  PERM_EDIT_TAXES: "Edit tax",
  PERM_DELETE_TAXES: "Delete tax",
};
